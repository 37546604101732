@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

    .stats {
        @apply bg-[#023047] text-white p-4 flex-1 rounded-md border-[#FFF] border-2;
    }

    .stats-container {
        @apply bg-[#023047] text-white p-5 rounded-lg border-[#FFF] border ;
    }

    .countdown {
        @apply p-8 text-5xl text-center text-white rounded-lg lg:min-w-[150px] bg-[#8ECAE6];
    }

    .countdown-label {
        @apply text-center text-white uppercase text-sm pt-4
    }

    .admin-button {
        @apply bg-[#023047] text-white p-2 flex-1 rounded-md border-2 hover:bg-[#219EBC]
    }

}